import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { routeBackCreateUserAction } from '../../../actions/routeBackCreateUserAction';
import { clearUsersAction } from '../../../actions/clearUsersAction';
import { getUsersSearchWithThunk } from '../../../api/usersThunk';
import { getUsersSearchAction } from '../../../actions/getUsersSearchAction';
import { defaultUsersSortingBy, defaultUsersSortingType, defaultUsersPageSize } from '../../../configurations/app';

class UsersListHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: ''
        }

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    static propTypes = {
        routeBackTo: PropTypes.func.isRequired,
        dispatchUsersSearch: PropTypes.func.isRequired,
        getUsersSearch: PropTypes.func.isRequired,
        updateMode: PropTypes.func.isRequired,
        clearUsers: PropTypes.func.isRequired
    };

    calculatePageSize() {
        let size = defaultUsersPageSize;
        return size;
    }

    handleSearch(e) {
        const { search } = this.props;
        if (e.key === "Enter" || e.target.name !== 'searchText') {
            const size = this.calculatePageSize();
            this.props.clearUsers();
            this.props.getUsersSearch(1, size, search.search_text, defaultUsersSortingBy, defaultUsersSortingType);
            this.props.updateMode(1, 'search');
        }
    }

    handleOnChange(e) {
        let search = {
            ...this.props.user,
            search_text: e.target.value
        };
        this.props.dispatchUsersSearch(search);
    }

    createUser() {
        const url = routeCodes.USERS_LIST;
        const source_page = "users_list";

        this.props.routeBackTo({ url, source_page })
        this.props.history.push(routeCodes.USER_CREATE);
    }

    componentWillUnmount() {
        let search = {
            ...this.props.user,
            search_text: ''
        };
        this.props.dispatchUsersSearch(search);
    }

    render() {
        const { search } = this.props;
        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-md-8 col-lg-9">
                        <h2 className="float-left mt-2 mr-4">Users</h2>
                        <button className="btn icoTextBtn btn-wrd_default" onClick={() => this.createUser()}><span
                            className="icon-wrd-add"></span> Create</button>
                    </div>
                    <div className="col-md-4 col-lg-3 text-right">
                        <div className="searchInput">
                            <span style={{ cursor: 'pointer' }} className="icon-wrd-search" onClick={this.handleSearch}></span>
                            <input type="text" onKeyPress={this.handleSearch} name="searchText" value={search.search_text} placeholder="Search..." onChange={this.handleOnChange} />
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

const mapStateToProps = ({ search }) => ({
    search: search.user
});

const mapDispatchToProps = (dispatch) => ({
    routeBackTo: (url) => dispatch(routeBackCreateUserAction(url)),
    dispatchUsersSearch: (data) => dispatch(getUsersSearchAction(data)),
    getUsersSearch: (pageIndex, pageSize, searchKeyword, SortingBy, SortingType) => dispatch(getUsersSearchWithThunk(pageIndex, pageSize, searchKeyword, SortingBy, SortingType)),
    clearUsers: () => dispatch(clearUsersAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersListHeader));