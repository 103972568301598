import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeCodes } from '../../../configurations/routes';
import { clearApplicationsAction } from '../../../actions/clearApplicationsAction';
import { getApplicationsSearchWithThunk } from '../../../api/applicationsThunk';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType, defaultApplicationsPageSize } from '../../../configurations/app';
import { getApplicationsSearchAction } from '../../../actions/getApplicationsSearchAction'

class ApplicationsListHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: ''
        }

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    static propTypes = {
        dispatchApplicationsSearch: PropTypes.func.isRequired,
        searchList: PropTypes.func.isRequired,
        updateMode: PropTypes.func.isRequired,
        clearApplications: PropTypes.func.isRequired
    };

    calculatePageSize() {
        let size = defaultApplicationsPageSize;
        return size;
    }

    handleSearch(e) {
        const { search } = this.props;
        if (e.key === "Enter" || e.target.name !== 'searchText') {
            this.props.clearApplications();
            this.props.searchList(search.search_text,1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType);
            this.props.updateMode(1, 'search');
        }
    }

    createApplication() {
        this.props.history.push(routeCodes.APPLICATION_CREATE);
    }

    handleOnChange(e) {
        let search = {
            ...this.props.user,
            search_text: e.target.value
        };
        this.props.dispatchApplicationsSearch(search);
    }

    componentWillUnmount() {
        let search = {
            ...this.props.applications,
            search_text: ''
        };
        this.props.dispatchApplicationsSearch(search);
    }

    render() {
        const { search } = this.props;
        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-md-8 col-lg-9">
                        <h2 className="float-left mt-2 mr-4">Applications</h2>
                        <button className="btn icoTextBtn btn-wrd_default" onClick={() => this.createApplication()}>
                            <span className="icon-wrd-add"></span> Create</button>
                    </div>
                    <div className="col-md-4 col-lg-3 text-right">
                        <div className="searchInput">
                        <span style={{ cursor: 'pointer' }} className="icon-wrd-search" onClick={this.handleSearch}></span>
                            <input id="application-list-search" type="text" onKeyPress={this.handleSearch} name="searchText" value={search.search_text} placeholder="Search..." onChange={this.handleOnChange} />
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

const mapStateToProps = ({ search }) => ({
    search: search.applications
});

const mapDispatchToProps = (dispatch) => ({
    dispatchApplicationsSearch: (data) => dispatch(getApplicationsSearchAction(data)),
    searchList: (keyword, pageIndex, pageSize, sortingBy, sortingType) => dispatch(getApplicationsSearchWithThunk(keyword, pageIndex, pageSize, sortingBy, sortingType)),
    clearApplications: () => dispatch(clearApplicationsAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsListHeader));